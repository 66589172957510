const addUser = (axiosInstance, fields) => {
	try {
		let users = fields.map((field) => {
			return field.value
		})
		let response = axiosInstance.post('/api/v1/users', users)
		return response;
	} catch (error) {
		return error;
	}
};

const fetchUsers = (axiosInstance, page, pageSize, query) => {
	try {
		let url = `/api/v1/users?page=${page}&pageSize=${pageSize}`
		if (query.length > 0) {
			url += `&query=${encodeURIComponent(query)}`
		}
		let response = axiosInstance.get(url)
		return response;
	} catch (error) {
		return error;
	}
};

const csvUpload = (axiosInstance, data) => {
	try {
		let response = axiosInstance.post('/api/v1/users', data)
		return response;
	} catch (error) {
		return error;
	}
};

const csvStatus = (axiosInstance, uploadedFileReferenceId) => {
	try {
		let response = axiosInstance.get(`/api/v1/users/upload-status/${uploadedFileReferenceId}`)
		return response;
	} catch (error) {
		return error;
	}
};

const deleteUser = (axiosInstance, userId) => {
	try {
		let response = axiosInstance.delete(`/api/v1/users/${userId}`)
		return response;
	} catch (error) {
		return error;
	}
};

const editUser = (axiosInstance, userId, data) => {
	try {
		// let params = buildURLQuery(data)
		let response = axiosInstance.put(`/api/v1/users/${userId}`, data)
		return response;
	} catch (error) {
		return error;
	}
};

const buildURLQuery = obj =>
	Object.entries(obj)
		.map(pair => pair.map(encodeURIComponent).join('='))
		.join('&');

const fetchUsersCount = (axiosInstance, orgId) => {
	try {
		let response = axiosInstance.get(`/api/v1/orgs/${orgId}/users-count`)
		return response;
	} catch (error) {
		return error;
	}
};

const getUserProfile = async (axiosInstance) => await axiosInstance.get('/api/v1/users/my-profile')

const getUserInfo = async (axiosInstance, id) => await axiosInstance.get(`ot/api/v1/users/${id}`)

const getImageProfilePicUploadInfo = async (axiosInstance) => await axiosInstance.get('/api/v1/users/my-profile/profile-picture/upload-info')

const uploadUserProfilePicture = async (axiosInstance, profilePictureUuid, hash) => await axiosInstance.put(`/api/v1/users/my-profile/profile-picture/${profilePictureUuid}?hash=${hash}`)

const getUserProfilePic = async (axiosInstance, userUuid, profilePictureUuid) => await axiosInstance.get(`/api/v1/users/${userUuid}/profile-picture/${profilePictureUuid}`)

const getUserDetails = async (axiosInstance, userUuid) => await axiosInstance.get(`/api/v1/users/${userUuid}/profile`)

const resendInvite = async (axiosInstance, userId) => await axiosInstance.put(`/api/v1/users/${userId}/resend-invitation`)

const deleteUserProfilePicture = async (axiosInstance, userId) => await axiosInstance.delete(`/api/v1/users/my-profile/profile-picture`)

const getUserDevices = async (axiosInstance, userId) => await axiosInstance.get(`/ot/api/v1/devices/user_devices.json?user_uuid=${userId}`)

const deleteUserDevices = async (axiosInstance, deviceId) => await axiosInstance.delete(`/ot/api/v1/devices/${deviceId}.json`)

export const getUserShiftStats = async (axiosInstance, userUuid, params) => await axiosInstance.get(`/ot/api/v1/shift_stats/user_stats.json?user_uuid=${userUuid}&${params}`)

export const downlodeUserLogCsv = async (axiosInstance, userUuid, params) => await axiosInstance.get(`/ot/api/v1/shift_stats/user_stats.csv?user_uuid=${userUuid}&${params}&include_all_days=false`)

export const getUserSummary = async (axiosInstance, userId, params) => await axiosInstance.get(`/ot/api/v1/users/${userId}/user_summary?${params}`)

const updateCinCout = async (axiosInstance, userId, params) => await axiosInstance.put(`/ot/api/v1/users/${userId}/update_cin_cout`, params)

export const verifyEmail = async (axiosInstance) => await axiosInstance.post(`/api/v1/users/resend-verification-email`, {})

export const verifyMyEmail = async (axiosInstance) => await axiosInstance.post(`/ot/api/v1/users/verify_my_account`)

export const resetPassword = async (axiosInstance, data) => await axiosInstance.put(`/api/v1/users/my-profile/reset-password`, data)

export const resetUserPassword = async (axiosInstance, userId, data) => await axiosInstance.put(`/api/v1/users/${userId}/reset-password`, data)

export const canEditUser = async (axiosInstance, userId) => await axiosInstance.get(`/ot/api/v1/users/${userId}/edit_settings`)

export const updateResellerAccessSettings = async (axiosInstance, data) => await axiosInstance.put(`/ot/api/v1/organization_settings/update_reseller_access`, data)

export const verifyEmailAvailability = async (axiosInstance, data) => await axiosInstance.post(`/ot/api/v1/signups/discover_email_availability`, data)

export const getUsersRole = async (axiosInstance, params) => await axiosInstance.get(`/ot/api/v1/users/user_roles.json?uuids=${params}`)


export default {
	addUser,
	getUserDetails,
	fetchUsers,
	csvUpload,
	csvStatus,
	deleteUser,
	editUser,
	fetchUsersCount,
	resendInvite,
	getImageProfilePicUploadInfo,
	getUserProfile,
	uploadUserProfilePicture,
	deleteUserProfilePicture,
	getUserDevices,
	deleteUserDevices,
	getUserShiftStats,
	downlodeUserLogCsv,
	getUserSummary,
	updateCinCout,
	verifyEmail,
	verifyMyEmail,
	resetPassword,
	getUserInfo,
	canEditUser,
	updateResellerAccessSettings,
	resetUserPassword,
	verifyEmailAvailability,
	getUsersRole
}
