import React, { useContext, useEffect, useState } from "react";
import oidcConfiguration from "../configuration";
import {
    AuthenticationProvider,
    oidcLog,
    InMemoryWebStorage
} from "@axa-fr/react-oidc-context";
import SessionRedirect from "../components/Authentication/SessionRedirect";
import CustomCallback from "../Pages/CustomCallback";
import SwitchPortal from "../components/SwitchPortal";
// Context
import { BrowserRouter as Router } from "react-router-dom";
import AccessTokenError from "../components/Authentication/AccessTokenError";
import AuthenticatingProgress from "../components/Authentication/AuthenticatingProgress";
import LocalWebStorageStore from "../components/Authentication/LocalWebStorageStore";
import MainContent from "./MainContent";
import SetHistory from "../core/SetHistory"
import { setGlobalUnreadMessageCount } from '../components/Messanger/containers/ChatUI/actions'
import { connect } from "react-redux";

const AppRouter = (props) => {
    const [activeLink, setActiveLink] = React.useState('/')
    const { setGlobalUnreadMessageCount, globalUnreadMessageCount } = props
    const [accessTokenRenewError, setAccessTokenRenewError] = useState(false);
    const AccessTokenRenewError = function (data) {
        const closeEvaButton = document.getElementById('close-eva-chat');
        if (closeEvaButton) {
            closeEvaButton.click();
        }
        setAccessTokenRenewError(true);
    }

    useEffect(() => {
        // When messenger is launched, hide global unread notification
        if (activeLink === '/messenger' && globalUnreadMessageCount > 0) setGlobalUnreadMessageCount(0)
    }, [activeLink, globalUnreadMessageCount])

    useEffect(() => {
        document.addEventListener("AccessTokenRenewError", data => AccessTokenRenewError(data))

        return function cleanup() {
            document.removeEventListener('AccessTokenRenewError', AccessTokenRenewError);
        }
    }, []);

    return (
        <MainContent>
            <AuthenticationProvider
                notAuthenticated={SessionRedirect}
                notAuthorized={SessionRedirect}
                sessionLostComponent={SessionRedirect}
                authenticating={AuthenticatingProgress}
                configuration={oidcConfiguration}
                loggerLevel={oidcLog.DEBUG}
                isEnabled={true}
                callbackComponentOverride={CustomCallback}
                UserStore={InMemoryWebStorage}>
                <Router>
                    {/* FIXME: how we can use secured component inside React's context api and start using this global context*/}
                    {/* As we want to access auth api ie logout, we need to wrap navbar inside auth component */}
                    {/* <ChatManager /> */}
                    {/* <ChatManagerProvider>
                            <AppDrawer />
                            <RouteRedirect />
                        </ChatManagerProvider> */}
                    <SetHistory setActiveLink={setActiveLink} />
                    <SwitchPortal />
                </Router>
            </AuthenticationProvider>
            {accessTokenRenewError && <AccessTokenError />}
        </MainContent>
    );
};

const mapStateToProps = function (state) {
    return {
        globalUnreadMessageCount: state.reducerChatUI.globalUnreadMessageCount,
    }
}

const mapDispatchToProps = {
    setGlobalUnreadMessageCount
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
